
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DepartmentService } from 'src/app/services/organization/department/department.service';
import { CorporatebrandService } from 'src/app/services/organization/corporatebrand/corporatebrand.service';
import { CommonUtilityService } from 'src/app/services/organization/commonutility/commonutility.service';
import { SectionService } from 'src/app/services/organization/section/section.service';
import { AccountService } from '../../../../shared/services/account.service'
import { constant } from '../../../../shared/const/constant'
import { Observable, Subscription } from 'rxjs';
import { SubindexService } from '../../../../services/organization/subindex/subindex.service';
import { MarkupGroupService } from '../../../../services/organization/department/markupgroup.service';
import { CustomCustomerGroupService } from 'src/app/services/organization/department/customcustomergroup.service';
import { ApplicationInsightService } from 'src/app/shared/services/application-insight.service';
import { UserAccess } from 'src/app/models/userAccess';
import { ModulePermission } from 'src/app/models/enum';
import { CommonValidationService } from 'src/app/services/organization/commonutility/commonValidation.service';

export class dropDownCollections {
  corporateBrandList: any;
  sectionList: any;
  garmentGroupList: any;
  subIndexList: any;
}

@Component({
  selector: 'app-departmentform',
  templateUrl: './departmentform.component.html',
  styleUrls: ['./departmentform.component.css']
})

export class DepartmentformComponent implements OnInit {
  updateDepartmentForm: any;
  isDepartmentEdit: any = false;
  isDraftWarning:any =false;
  seasonByRangeList: any[] = [];
  startSeasonMasterlist: any[] = [];
  startSeasonFilterList: any[] = [];
  endSeasonMasterlist: any[] = [];
  endSeasonFilterList: any[] = [];
  sectionMasterList: any[] = [];
  sectionFilterList: any[] = [];
  sectionNameList: any[] = [];
  garmentGroupMasterList: any[] = [];
  garmentGroupFilterList: any[] = [];
  customerGroupMasterList: any[] = [];
  customerGroupFilterList: any[] = [];
  customerGroupNameFilterCGList: any[] = [];
  customerGroupNameMasterCGList: any[] = [];
  corporateBrandMasterlist: any[] = [];
  corporateBrandFilterList: any[] = [];
  corporateBrandId: FormControl = new FormControl('', [Validators.required, this._commonutilityService.acObjectValidator()]);
  corporateBrandNameFrCascade: any;
  //Tabs for Sub index, Custom customer group,Markup group
  public depSubTab = true;
  public depSubIndexTab = true;
  public depCustomCustomerGroupTab = false;
  public depMarkupGroupTab = false;
  depSubIndexList: any;
  depCustomCustomerGroupList: any;
  depMarkupGroupList: any;
  parentDepartmentId: any;
  subIndexSeasonlist: any;
  markupSeaasonlist: any[] = [];
  subIndexList: any[] = [];
  markupGroupList: any[] = [];
  customCustomerGrouplist: any[] = [];
  seasonList: any;
  validationMessages: any;
  valid: boolean = false;
  subscription: Subscription
  private eventsSubscription: Subscription;
  @Input() events: Observable<void>;
  @Output() dropDownCollections = new EventEmitter();
  departmentDraft : boolean = false;
  Isdraft: any;
  userrole: boolean;
  users:any;
  constructor(
    public _corporatebrandService: CorporatebrandService,
    public _subIndexService: SubindexService,
    public _departmentMarkupGroupService: MarkupGroupService,
    public _departmentService: DepartmentService,
    public _commonutilityService: CommonUtilityService,
    public _sectionService: SectionService,
    private toastr: ToastrService,
    public account: AccountService,
    public _depCustomCustomerGroupService: CustomCustomerGroupService,
    private applicationInsightService:ApplicationInsightService,
    public _userAccess: UserAccess
  ) {
    this.users = JSON.parse(localStorage.getItem("users") || "[]")
   }

  ngOnInit(): void {
    this.applicationInsightService.logEvent("Department form page Initialization started");
    this.userrole = this.users.includes(ModulePermission.Organization_Write) ? true : false;
    this.departmentForm.controls.lastUpdatedBy.setValue(JSON.parse(localStorage.getItem("UserInfo")).Email || "[]");
    this.updateDepartmentForm = Object.assign({ active: true, draft: true });
    this.loaddropdownCollection();
    this.subscription = this._departmentService.departmentRowData$.subscribe(
      departmentId => {
      this.fnUpdateDepartment(departmentId,1)
      });
    this.validationMessages = this._commonutilityService.validationMsgs;
    this.fnSetControlsValidation();
    if (this.events != null || undefined) {
      this.eventsSubscription = this.events.subscribe(() => this.onClear());
    }
    this.applicationInsightService.logEvent("Department form page Initialization ended");
  }
  loaddropdownCollection() {
    this.applicationInsightService.logEvent("Department form page load dropdown Collections started");
    this._corporatebrandService.getCorporateBrandList().subscribe((data: any) => {
      this.corporateBrandMasterlist = this.corporateBrandFilterList = data.getCorporateBrandAll.filter(object => {
        return object['active'] !== false;
      })
      this.corporateBrandFilterList = this.corporateBrandMasterlist.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this.dropDownCollectionfn();
    });
    this._sectionService.getSectionAll().subscribe((data: any) => {
      this.sectionMasterList = this.sectionNameList = data.section.filter(object => { return object['active'] !== false; });
      this.sectionFilterList = this.sectionMasterList.sort((a, b) => (a.sectionCode > b.sectionCode ? 1 : -1));
      this.dropDownCollectionfn();
    });
    this._departmentService.getSeasonAll().subscribe((data: any) => {
      this.seasonList = data.seasonByRange;
      this.seasonList = this.seasonList?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
    });
    this._departmentService.getDepartmentSeasonByRange(10).subscribe((data: any) => {
      this.seasonByRangeList = this.startSeasonMasterlist = this.startSeasonFilterList = data.seasonByRange;
      this.endSeasonMasterlist = this.endSeasonFilterList = data.seasonByRange;
      this.endSeasonMasterlist = this.endSeasonMasterlist?.sort((a, b) => (a?.seasonNumber < b?.seasonNumber ? 1 : -1));
      this.dropDownCollectionfn();
    });
    this._departmentService.getGarmentGroupAll().subscribe((data: any) => {
      this.garmentGroupMasterList = this.garmentGroupFilterList = data.garmentGroup.filter(object => { return object['active'] !== false; });
      this.garmentGroupFilterList = this.garmentGroupMasterList.sort((a, b) => (a.garmentGroupCode > b.garmentGroupCode ? 1 : -1));
      this.dropDownCollectionfn();
    });
    this._subIndexService.getSubIndexList().subscribe((data: any) => {
      this.subIndexList = data.subIndex;
      this.dropDownCollectionfn();
    });
    this._departmentMarkupGroupService.getDepartmentMarkupGroupAll().subscribe((data: any) => {
      this.markupGroupList = data.markupGroup;
    });
    this._depCustomCustomerGroupService.getDepartmentCustomCustomerGroupAll().subscribe((data: any) => {
      this.customCustomerGrouplist = data.departmentCustomCustomerGroup
    });
    this.applicationInsightService.logEvent("Department form page load dropdown Collections ended");
  }
  public dropDownCollectionfn() {
    let obj = new dropDownCollections();
    if (this.corporateBrandMasterlist.length > 0 && this.garmentGroupMasterList.length > 0 && this.sectionMasterList.length > 0 && this.subIndexList.length > 0) {
      obj.corporateBrandList = this.corporateBrandMasterlist;
      obj.garmentGroupList = this.garmentGroupMasterList;
      obj.sectionList = this.sectionMasterList;
      obj.subIndexList = this.subIndexList;
      this.dropDownCollections.emit(obj);
    }
  }
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
    this.subscription.unsubscribe(); // onDestroy cancels the subscribe request
  }
  displayTextCB(corporateBrandId: string) {
    this.applicationInsightService.logEvent("Department form corporateBrandName display started");
    let findlist: any[] = [];
    if (corporateBrandId === null || corporateBrandId === "")
      return null;
    else if (this.corporateBrandMasterlist != undefined && corporateBrandId != null)
      findlist = this.corporateBrandMasterlist;      
    if (findlist != null || undefined) {
      this.corporateBrandNameFrCascade = (findlist.find(x => x.corporateBrandId == corporateBrandId).corporateBrandCodeName)
    }
    this.applicationInsightService.logEvent("Department form corporateBrandName display ended");
    return this.corporateBrandNameFrCascade;
   }
  filterItemCB(filterKeyCB: string) {
    this.applicationInsightService.logEvent("Department form corporateBrandName filter started");
    if (filterKeyCB !== null && filterKeyCB !== "") {
      this.corporateBrandFilterList = this.corporateBrandMasterlist.filter(cb => cb.corporateBrandName.toLowerCase().includes(filterKeyCB.toString().toLowerCase()))
      this.corporateBrandFilterList = this.corporateBrandFilterList.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      if (typeof filterKeyCB === "number")
        this.filterSectionByCB(this.corporateBrandId.value)
        this.filterCustomerGroupByCBId(this.corporateBrandId.value);
    }
    else {
      this.corporateBrandFilterList = this.corporateBrandMasterlist.sort((a, b) => a.corporateBrandCode.localeCompare(b.corporateBrandCode));
      this.departmentForm.controls.sectionId.reset();
      this.sectionFilterList = [];
     };
    this.applicationInsightService.logEvent("Department form corporateBrandName filter ended");
  }
  
  filterSectionByCB(corporateBrandId: any) {
    this.applicationInsightService.logEvent("Department form filter section by corporateBrandName started");
    this.sectionNameList = [];
    this.sectionFilterList = this.sectionNameList = this.sectionMasterList.filter(object => { return object['corporateBrandId'] === corporateBrandId; })
    this.sectionFilterList = this.sectionNameList = this.sectionFilterList.sort((a, b) => (a.sectionCode > b.sectionCode ? 1 : -1));
    this.applicationInsightService.logEvent("Department form filter section by corporateBrandName ended");
  }
  filterCustomerGroupByCBId(corporateBrandId: any) {
    this.applicationInsightService.logEvent("Department form filtering Customergroup by corporatebrand id Started");
    this.customerGroupNameFilterCGList = this.customerGroupNameMasterCGList.filter(x => x.corporateBrandId == corporateBrandId && x.active == true);
    this.customerGroupNameFilterCGList = this.customerGroupNameFilterCGList.sort((a, b) => (a.customerGroupCode> b.customerGroupCode? 1 : -1));
    this.applicationInsightService.logEvent("Department form filtering Customergroup by corporatebrand id Ended");
  }
  displayTextSection(sectionId: string) {
    var retValue = null;
    this.applicationInsightService.logEvent("Department form section Name display started");
    if (sectionId == null || sectionId == "")
      retValue = null;
    else if (this.sectionMasterList != undefined && sectionId != null)
      var sectionConcate = this.sectionMasterList.find(x => x.sectionId === sectionId);
    if (sectionConcate != null)
      retValue = sectionConcate.sectionCode + "-" + sectionConcate.sectionName;
    else
      retValue = null;
    this.applicationInsightService.logEvent("Department form section Name display ended");
    return retValue;
  }
  filterItemSection(filterKeyS: string) {
    this.applicationInsightService.logEvent("Department form section filter started");
    if (filterKeyS != null && filterKeyS != "" && typeof this.corporateBrandId.value == "number") {
      this.sectionFilterList = this.sectionNameList.filter(s => (s.sectionCode + "-" + s.sectionName).toLowerCase().includes(filterKeyS.toString().toLowerCase()));
    }
    else if (typeof this.corporateBrandId.value == "number")
      this.sectionFilterList = this.sectionNameList;
      this.applicationInsightService.logEvent("Department form section filter ended");
  }
  displayTextSeason(season: string) {
    if (season == null || season == "" || season.includes("99"))
      return null;
    else if (this.startSeasonMasterlist != undefined && season != null)
      return this.startSeasonMasterlist.find(x => x.seasonNumber === season).displayName;
  }
  filterStartSeason(filterKeySS: string) {
    this.applicationInsightService.logEvent("Department form StartSeason filter started");
    if (filterKeySS != null && filterKeySS != "") {
      this.startSeasonFilterList = this.startSeasonMasterlist.filter(rs => rs.displayName.toLowerCase().includes(filterKeySS.toString().toLowerCase()))
      this.fnSetControlsValidation();
    }
    else
      {this.startSeasonFilterList = this.startSeasonMasterlist;}
      this.applicationInsightService.logEvent("Department form StartSeason filter ended");
  }
  filterEndSeason(filterKeyES: string) {
    this.applicationInsightService.logEvent("Department form EndSeason filter started");
    if (filterKeyES != null && filterKeyES != "") {
      this.endSeasonFilterList = this.endSeasonMasterlist.filter(rs => rs.displayName.toLowerCase().includes(filterKeyES.toString().toLowerCase()))
      this.fnSetControlsValidation();
    }
    else
      {this.endSeasonFilterList = this.endSeasonMasterlist;}
      this.applicationInsightService.logEvent("Department form EndSeason filter ended");
  }
  displayTextGarmentGroup(garmentGroupId: string) {
    if (garmentGroupId == null || garmentGroupId == "")
      return null;
    else if (this.garmentGroupMasterList != undefined && garmentGroupId != null)
      var garmentGroupConcate = this.garmentGroupMasterList.find(x => x.garmentGroupId === garmentGroupId);
    if (garmentGroupConcate != null)
      return garmentGroupConcate.garmentGroupCode + "-" + garmentGroupConcate.garmentGroupName;
    return null;
  }
  filterItemGarmentGroup(filterKeyGG: string) {
    this.applicationInsightService.logEvent("Department form GarmentGroup filter started");
    if (filterKeyGG != null && filterKeyGG != "")
      this.garmentGroupFilterList = this.garmentGroupMasterList.filter(gg => (gg.garmentGroupCode + "-" + gg.garmentGroupName).toLowerCase().includes(filterKeyGG.toString().toLowerCase()))
    else
      {this.garmentGroupFilterList = this.garmentGroupMasterList;}
      this.applicationInsightService.logEvent("Department form GarmentGroup filter ended");
  }
  draftChange(event:any){
   if(this.isDepartmentEdit==true && event.checked==true && this.departmentForm.controls.draft.value==true){ 
       this.isDraftWarning=true;
      }
      else if(this.Isdraft){
        this.isDraftWarning=true;
      }
      else{
        this.isDraftWarning=false;
      }
  }
  onSubmit() {
    this.applicationInsightService.logEvent("Department form Submit started");
    if (this.departmentForm.controls.active.value === undefined || this.departmentForm.controls.active.value === null) { this.departmentForm.controls.active.setValue(false); }
    if (this.departmentForm.controls.draft.value === undefined || this.departmentForm.controls.draft.value === null) { this.departmentForm.controls.draft.setValue(true); }
    if (this.departmentForm.controls.globalInShopWeek.value === undefined || this.departmentForm.controls.globalInShopWeek.value === null) { this.departmentForm.controls.globalInShopWeek.setValue(false); } this.departmentForm.controls.departmentCode.updateValueAndValidity();
    if (this.departmentForm.controls.departmentName.value != undefined && this.departmentForm.controls.departmentName.value != "" && this.departmentForm.controls.departmentName.value != null) { this.departmentForm.controls.departmentName.setValue(this.departmentForm.controls.departmentName.value.trim()); }
    this.corporateBrandId.updateValueAndValidity();
    this.departmentForm.controls.departmentName.updateValueAndValidity();
    this.departmentForm.controls.startSeason.updateValueAndValidity();
    this.departmentForm.controls.endSeason.updateValueAndValidity();
    this.departmentForm.controls.sectionId.updateValueAndValidity();
    this.departmentForm.controls.garmentGroupId.updateValueAndValidity();
    console.log("DEPARTMENT Save: " + JSON.stringify(this.departmentForm.value))
    if (this.departmentForm.valid) {
      console.log("DEPARTMENT AFTER " + JSON.stringify(this.departmentForm.value))
      if (this.departmentForm.value.departmentId == undefined || this.departmentForm.value.departmentId == 0 || this.departmentForm.value.departmentId == null) {
        this._departmentService.createDepartment(this.departmentForm.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
            this._departmentService.refreshDepartmentList();
            console.log("Save & Proceed DepartmentID-" + data.departmentId);
            this.departmentForm.controls.departmentId.setValue(data.departmentId);
            this.parentDepartmentId = data.departmentId;
            this.subIndexSeasonlist = this.markupSeaasonlist = this.startSeasonMasterlist;
            this.isDepartmentEdit = true;
            this.depSubTab = true;

            if (this.depCustomCustomerGroupTab != true && this.depMarkupGroupTab != true)
              this.depSubIndexTab = true;
          },
            error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
      }
      else {
        console.log("DEPARTMENT UPDATE")
        this._departmentService.updateDepartment(this.departmentForm.value).subscribe
          (data => {
            this.toastr.success(data.message, "", { timeOut: constant.toasterTimeout });
            this._departmentService.refreshDepartmentList();
            this.onClear();
          },
            error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
      }
    }
    this.applicationInsightService.logEvent("Department form Submit ended");
  }
  onClear() {    
    this.applicationInsightService.logEvent("Department form clear started");
    this.displayTextSection(null);
    this.displayTextGarmentGroup(null);
    this.displayTextSeason(null);
    this.startSeasonMasterlist = this.startSeasonFilterList = this.seasonByRangeList;
    this.endSeasonMasterlist = this.endSeasonFilterList = this.seasonByRangeList;
    this.isDepartmentEdit = false;
    this.corporateBrandId.clearValidators();
    this.corporateBrandId.setValue('');
    this.departmentForm.controls.departmentCode.clearValidators();
    this.departmentForm.controls.departmentName.clearValidators();
    this.departmentForm.controls.startSeason.clearValidators();
    this.departmentForm.controls.endSeason.clearValidators();
    this.departmentForm.controls.sectionId.clearValidators();
    this.departmentForm.controls.garmentGroupId.clearValidators();
    this.departmentForm.controls.departmentId.reset();
    this.departmentForm.controls.departmentCode.reset();
    this.departmentForm.controls.departmentName.reset();
    this.departmentForm.controls.globalInShopWeek.setValue(false);
    this.departmentForm.controls.startSeason.reset();
    this.departmentForm.controls.endSeason.reset();
    this.departmentForm.controls.sectionId.reset();
    this.departmentForm.controls.garmentGroupId.reset();
    this.departmentForm.controls.active.setValue(true);
    this.departmentForm.controls.draft.setValue(true);
    this.fnSetControlsValidation();
    this.depSubTab = true;
    this.depSubIndexTab = false;
    this.depCustomCustomerGroupTab = false;
    this.depMarkupGroupTab = false;
    this.parentDepartmentId = null;
    this.depCustomCustomerGroupList = null;
    this.depMarkupGroupList = null;
    this.depSubIndexList = null;
    this.isDraftWarning=false;
    this.applicationInsightService.logEvent("Department form clear ended");
  }
  departmentForm: FormGroup = new FormGroup(
    {
      departmentId: new FormControl(''),
      departmentCode: new FormControl(''),
      departmentName: new FormControl(''),
      globalInShopWeek: new FormControl(false),
      startSeason: new FormControl(''),
      endSeason: new FormControl(''),
      sectionId: new FormControl(),
      garmentGroupId: new FormControl(),
      active: new FormControl(true),
      draft: new FormControl(true),
      lastUpdatedBy: new FormControl(),
    })
  fnSetControlsValidation() {
    this.corporateBrandId.setValidators([Validators.required, this._commonutilityService.acObjectValidator()]);
    this.departmentForm.controls.departmentCode.setValidators([Validators.required, Validators.min(1000), Validators.max(9999)]);
    this.departmentForm.controls.departmentName.setValidators([Validators.required, Validators.maxLength(50)]);
    this.departmentForm.controls.startSeason.setValidators([this._commonutilityService.acStartSeasonValidator(this.departmentForm.controls.endSeason.value, null), Validators.required]),
    this.departmentForm.controls.endSeason.setValidators([this._commonutilityService.acEndSeasonValidator(this.departmentForm.controls.startSeason.value, null)]);
    this.departmentForm.controls.sectionId.setValidators([this._commonutilityService.acObjectValidator(), Validators.required]);
    this.departmentForm.controls.garmentGroupId.setValidators([this._commonutilityService.acObjectValidator(), Validators.required]);
  }
  fnUpdateDepartment(departmentId: any,methodCall:number) {    
    this.applicationInsightService.logEvent("Update Department started");
    this.isDraftWarning=false;
    this.departmentForm.controls.startSeason.clearValidators();
    this.departmentForm.controls.endSeason.clearValidators();
    if(methodCall==1){
      this.departmentForm.controls.startSeason.reset();
      this.departmentForm.controls.endSeason.reset();
      }
    this.parentDepartmentId = departmentId;
    this._departmentService.getDepartmentById(departmentId).subscribe((data: any) => {
    if(methodCall==1){
      this.updateDepartmentForm = data.department;
      this.Isdraft=this.departmentDraft = data.department.draft == true ? true : false;
   }
    let foundStart = this.startSeasonMasterlist.some(el => el.seasonNumber === this.updateDepartmentForm.startSeason);
    if (!foundStart)
    this.startSeasonFilterList = this.startSeasonMasterlist = this.seasonList.filter(x => x.seasonNumber >= this.updateDepartmentForm.startSeason);
    methodCall==1 ?this.departmentForm.controls.startSeason.setValue(this.updateDepartmentForm.startSeason):'';
    this.endSeasonFilterList = this.seasonList.filter(x => x.seasonNumber >= this.updateDepartmentForm.endSeason);
    methodCall==1 ? this.departmentForm.controls.endSeason.setValue(this.updateDepartmentForm.endSeason):'';
    let foundEnd = this.startSeasonMasterlist.some(el => el.seasonNumber === this.updateDepartmentForm.startSeason);
    if (this.endSeasonFilterList.length == 0 || foundEnd)
    this.endSeasonFilterList = this.endSeasonMasterlist;
    else
    this.endSeasonFilterList = this.endSeasonMasterlist = this.seasonList.filter(x => x.seasonNumber >= this.updateDepartmentForm.endSeason);
    
    if (data.department != null) {
    this.depSubIndexList = data.department.subIndex == null ? [] : data.department.subIndex
    }    
    this.depCustomCustomerGroupList = data.department.customCustomerGroup == null ? [] : data.department.customCustomerGroup
    this.depMarkupGroupList = data.department.markUpGroup == null ? [] : data.department.markUpGroup
    this.corporateBrandId.setValue(data.department.corporateBrandId);
    this.subIndexSeasonlist = this.markupSeaasonlist = this.startSeasonMasterlist;
    this.subIndexList = this.subIndexList;
    this.markupGroupList = this.markupGroupList;
    this.isDepartmentEdit = true;
    this.depSubTab = true;
    if (this.depCustomCustomerGroupTab != true && this.depMarkupGroupTab != true)
    this.depSubIndexTab = true;
    if(this.Isdraft && this.departmentForm.controls.draft.value==true){
      this.isDraftWarning=true;
    }
    },
    error => { this.toastr.error(error, "", { timeOut: constant.toasterTimeout }) })
    this.applicationInsightService.logEvent("Update Department ended");
    }

  loadTabComponentOnClck(tapComponent: string) {
    this.applicationInsightService.logEvent("Department Subtab load started");
    if (this.departmentForm.dirty == false && (this.parentDepartmentId == undefined || this.parentDepartmentId == null))
      this.onClear();
    if (tapComponent === "Dep-SubIndex") {
      this.depSubIndexTab = true;
      this.depCustomCustomerGroupTab = false;
      this.depMarkupGroupTab = false;
      this.parentDepartmentId = this.parentDepartmentId;
    }
    else if (tapComponent === "Dep-CustCustomerGroup") {
      this.depSubIndexTab = false;
      this.depCustomCustomerGroupTab = true;
      this.depMarkupGroupTab = false;
      this.parentDepartmentId = this.parentDepartmentId;
    }
    else if (tapComponent === "Dep-MarkupGroup") {
      this.depSubIndexTab = false;
      this.depCustomCustomerGroupTab = false;
      this.depMarkupGroupTab = true;
      this.parentDepartmentId = this.parentDepartmentId;
    }
    this.applicationInsightService.logEvent("Department Subtab load ended");
  }
}

